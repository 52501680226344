import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image"
import {Section, Container, SectionTitle, SectionDescription, MarkdownContent, BreadCrumb } from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import JoinForm from "../components/JoinForm"

const Grid = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  margin:0 -15px;
  @media (min-width: 992px) { 
    margin:0 -30px;
  }
`
const GridItem = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  margin-bottom:40px;
  @media (min-width: 992px) { 
    padding:0 30px;
    max-width:50%;
    flex:0 0 50%;
  }
  @media (min-width: 1200px) { 
  margin-bottom:80px;
  }
`
const MediaThumbnail = styled.div`
  position:relative;
  padding-left: 55px;
  @media (min-width: 768px) { 
    padding-left: 85px;
  }
  &:before{    
    position: absolute;
    left:0;
    transform-origin: 0 0;
    transform: rotate(270deg);
    content: attr(data-label);
    transform: rotate(-90deg);
    color: rgba(19,20,19,0.14);   
    bottom:-15px;
    
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    @media(min-width:992px){
      font-size: 50px;
      line-height: 50px;
      bottom:-20px;
    }
    @media(min-width:1200px){
      font-size:70px;
      line-height:70px;
      bottom:-25px;
    }
  } 
`
const MediaBody = styled.div`
  position:relative;
  padding:10px 10px 20px;
  border-radius:15px;
  background-color:#fff;
  border: 1px solid #f5f5f5;
  box-shadow:0 0 5px rgba(0,0,0,0.05);
 
`
const MediaFigure = styled.div`
  .gatsby-image-wrapper{
    img{
      border-radius:15px;
    }
  } 
`
const MediaCaption = styled.div`
  position:relative;
  text-align:center;  
  padding:10px 15px;
  margin: -30px 10px 0;
  @media (min-width: 1200px) {
    padding:20px 15px;
    margin: -40px 15px 0;
  }
  &:before{
    content: "";
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #f5f5f5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }
`
const MediaTitle = styled.div`
  position:relative;
  text-transform: uppercase;
  font-weight:700;
  font-size:18px;
  line-height:28px; 
  @media(min-width:992px){
    font-size:20px;
    line-height:30px; 
  }
  @media(min-width:1600px){
    font-size:26px;
    line-height:40px;
  }
`
const MediaDesc = styled.div`
  p{
    margin:15px 0;
  }
`

const RoofStylePage = ({ data, location }) => {
  const pageData = data.contentfulPageRoofStyles;

  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#F8FAFB" bgBefore="#002765" bgBeforeHeight="80%"  bgBeforeWidth="100%">
        <HeroBanner className="banner-hero" bgBefore="block" data-shadowtext="Roof Styles">
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb><Link to='/'>Home</Link><span>Roof Styles</span></BreadCrumb>
              <HeroContent>
                <HeroLeft><HeroTitle>{pageData.heroTitle}</HeroTitle></HeroLeft>
                <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>
      
      <Section ept="60px" epb="120px" xpt="60px" xpb="60px" pt="40px" pb="40px" bgColor="#F8FAFB" className="section-roof">
        <Container>
          <SectionTitle>{pageData.roofStyleSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1170px" ml="0" mb="30px">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.roofStyleSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          {
            pageData.roofStyles.map((item, i) => (
              <Grid>
                <GridItem key={i}>            
                  <MediaThumbnail left="-65px" data-label={item.subTitle}>
                    <MediaBody>
                      <MediaFigure><GatsbyImage image={item.image.gatsbyImageData} alt={item.title} /></MediaFigure>
                      <MediaCaption><MediaTitle>{item.title}</MediaTitle></MediaCaption>
                    </MediaBody>
                  </MediaThumbnail>
                </GridItem>
                <GridItem>
                  <MediaDesc>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description.childMarkdownRemark.html,
                      }}
                    />
                  </MediaDesc>
                </GridItem>
              </Grid>
            ))
          }
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </Container>
      </Section>
    </Layout>
  );
};

export default RoofStylePage

export const pageQuery = graphql`
  query RoofStylePagePageQuery {
    contentfulPageRoofStyles {
      metaTitle
      metaDescription
      heroTitle
      roofStyleSectionTitle
      roofStyleSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofStyles {
        title
        subTitle
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`